<template>
  <BaseLayout :custom-class="{coming_soon: true}">
    <SubHeader :custom-class="{coming_soon_sub_header: true}"></SubHeader>
    <div id="bg-container" style="padding-top: 40px;">
      <h1 style="text-align: center; color: #fff;">Mane Street Market</h1>
      <h2 style="text-align: center; font-style: italic; color: #fff;">Coming Soon!</h2>
      <div class="d-flex justify-content-center" style="margin-top: 50px;">
        <div style="margin-right: 30px; width: 300px; text-align: center">
          <img src="../assets/img/coming_soon_real_estate.png" width="260" />
          <h3 style="color: #fff; font-style: italic">Equestrian Real Estate</h3>
        </div>
        <!-- <div style="margin-left: 30px; width: 300px; text-align: center">
          <img src="../assets/img/coming_soon_auctions.png" width="260" />
          <h3 style="color: #fff; font-style: italic">Equine Auctions</h3>
        </div> -->
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';

export default {
  components: {BaseLayout, SubHeader},
  mounted() {
    document.title = 'Coming Soon - Mane Street Market';
    window.scrollTo(0, 0);
  }
}
</script>

<style lang="scss">
.coming_soon {
  padding-bottom: 0 !important;
}

.coming_soon_sub_header {
  margin-bottom: 0 !important;
}
</style>

<style lang="scss" scoped>
#bg-container {
  background-image: url("../assets/img/comingsoonpage.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 698px;
}

</style>